@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Montserrat:200,400,500,600,700');
@import "laravel-mentions";
@import "../../../node_modules/bulma/sass/utilities/initial-variables.sass";
$family-sans-serif: 'Montserrat', sans-serif;
//$widescreen-enabled:false !default
$green: #009F91;
$color-brand: #00839f;
$link: $color-brand;
$side-menu-width: 230px;
$primary: $color-brand;
$widescreen: 960px + (2 * $gap) !default;
$fullhd: 960px + (2 * $gap) !default;
$navbar-height: 4.25rem;
$navbar-item-img-max-height: 2.75rem;
$body-size: 15px;
$tabs-link-padding: 0.1em 0.5em;
$menu-list-link-padding: 0.9em 0.75em;
$menu-item-hover-color: $green;
$menu-item-hover-background-color: $white;
$navbar-item-color: $color-brand;
@import "../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../../node_modules/bulma/sass/base/_all.sass";
@import "../../../node_modules/bulma/sass/grid/_all.sass";
@import "../../../node_modules/bulma/sass/elements/_all.sass";
@import "../../../node_modules/bulma/sass/helpers/_all.sass";
@import "../../../node_modules/bulma/sass/form/_all.sass";
@import "../../../node_modules/bulma/sass/components/navbar.sass";
@import "../../../node_modules/bulma/sass/components/tabs.sass";
@import "../../../node_modules/bulma/sass/components/menu.sass";
@import "../../../node_modules/bulma/sass/components/card.sass";
@import "../../../node_modules/bulma/sass/components/level.sass";
@import "../../../node_modules/bulma/sass/components/media.sass";
@import "../../../node_modules/bulma/sass/layout/hero.sass";
@import "../../../node_modules/bulma/sass/layout/section.sass";

/*::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar:horizontal {
  width: 5px;
}
::-webkit-scrollbar-track {
  width: 5px;
  background: #f5f5f5;
}*/

/*::-webkit-scrollbar-thumb {
  width: 1em;
  background-color: #ddd;
  outline: 1px solid slategrey;
  border-radius: 1rem;
}*/
html{
  height: 100%;
}
body {
  min-height: 100%;
  background: #F2F3F8;
}
#app-header {
  margin: 0;
}
#app-content {
  //background: #F2F3F8;
  margin: 0;
  //padding-top: 5rem;
  //min-height: 100vh;
}
#navigation {
  padding: 0;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .1);
  background-color: $white;
}

#navigation .menu {
  font-size: .9rem;

}
#page-content {
  @include from($tablet){
    padding: 2rem;
  }
}

.logo {
  text-align: center;
  width: 100%;
  align-self: center;
  img {
    max-height: 50px;
    vertical-align: middle;

  }
}
.logo-universe {
  text-align: center;
  width: 100%;
  margin-top: 35px;
  img {
    max-height: 50px;

  }
}
.no-padding {
  padding: 0 !important;
}
@include mobile{
  .no-padding-mobile{
    padding: 0 !important;
  }

  .card-content-mobile{
    padding: 0.75rem;
  }

  .table-container {
    width: 100%;
  }
}
canvas {
  display: block;
  vertical-align: bottom;
}
.bg-green-blue {
  background-color: $color-brand!important;
  color: #fff!important;
}

h1 {
  font-size: 40px;
}

.loading {
  display: none;
}

.bg-f5 {
  background-color: #f5f5f5!important;
  color: #333!important;
}


.navbar-brand {
  @include from($desktop){
    justify-content: center;
    width: $side-menu-width;
  }

  @include mobile {
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .1);
  }
  @include tablet-only {
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .1);
  }
}

.navbar-item img {
  max-height: 50px;
}

.side-menu {
  width: $side-menu-width;
}

.menu-list {
  li {
    margin: 5px 10px;
  }
  a {
    color: $color-brand;
    border-radius: 20px;
    border: 1px solid $white;
    text-transform: uppercase;
    font-weight: 900;
    span {
      margin-left: 10px;
      font-weight: 600;
    }

    &:hover {
      border: 1px solid $color-brand;
      color: $color-brand;
    }
    &.is-active:hover {
      color: $white
    }
  }
}
.media-student {
  .icon-background {
    background-color: #00a47a;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
  }

  &.is-dashboard {
    .media-left {
      margin-right: 0;
    }
    h6 {
      padding: 0;
    }
    .document-checker {
      display: inline-block;
      li {
        display: inline-block;
        text-align: center;
        p {
          font-size: 8px;
          margin-bottom: 0;
        }
      }
    }
    .tracking-box {
      padding: 0 10px;
      height: 38px;
      p {
        font-size: 12px;
      }
    }
  }
}

.tracking-box {
  padding: 10px 15px;
  border-radius: 5px;
  display: table;
  height: 50px;
  width: 100%;
  position: relative;
  &.is-done {
    background-color: $color-brand;
    .icon-status {
      color: $color-brand;
    }
  }
  &.is-pending {
    background-color: $color-brand;
    .icon-status {
      color: $color-brand;
    }
  }
  &.is-waiting {
    background-color: #ffffff;
    border: 2px solid #43C0B7;
    p {
      color: #000000;
    }
    .icon-status {
      border: 1px solid #43C0B7;
      color: #43C0B7;
    }
  }
  p {
    color: #ffffff;
    font-size: 13px;
    text-align: center;
    margin-bottom: 0;
    display: table-cell;
    vertical-align: middle;
    line-height: 14px;
    font-weight: 500;
    .done-at {
      font-size: 7px;
    }
  }

  .icon-status {
    background-color: #ffffff;
    position: absolute;
    top: 1px;
    border-radius: 25px;
    right: 2px;
    i {
      font-size: 10px;
    }
  }
}
.navbar-burger {
  &:hover {
    background-color: transparent;
    color: $color-brand;
  }
  &:before {
     content: "MENU";
     position: absolute;
     margin-left: -31px;
     margin-top: 22px;
     font-weight: 500;
   }
  span {
    height: 2px;
  }
}

nav.navbar {
  border-top: 4px solid $color-brand;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .1);
}
#touchMenu {
  border-top: 1px solid $color-brand;
}
.progress-bar {
  margin: 30px;
  .title {
    text-transform: uppercase;
    font-weight: 900;
    font-size: .6rem;
    margin-bottom: .3rem
  }
}
.tabs {
   ul {
     justify-content: center;
   }
 }
.consultant {
  margin-top: 20px;
  margin-bottom: 20px;
  .about {

  }
}
.list-student {
  .header {
    background-color: $color-brand;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #fff;
    text-align: center;
    padding: 10px;
    font-size: 21px;
    font-weight: 600;
  }
  .content {
    figure {
      margin-left: 0;
      margin-right: 0;

    }
    ul.ul-student {
      margin-left: 0;
      margin-top: 0;
      li.li-student {
        list-style: none;
        margin-bottom: 0;
        padding: 10px 5px;
        border: 1px solid $color-brand;
        border-top-width: 0;
        //border-radius: 15px;
        a {
          color: #000;
          text-decoration: none;
        }
        &:first-child {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
        &:last-child {
          border-bottom-right-radius: 15px;
          border-bottom-left-radius: 15px;
        }
      }

      li+li {
        margin-top: 0;
      }
    }
  }

}
.list-quotation-title {
  margin: 0;
  padding: 15px 0;
  font-weight: 700;
  line-height: 1.2;
}
.box-document {
  margin-bottom: 50px;
  padding-bottom: 30px;
  border-bottom: 1px solid #dddfea;
}

.social-media-mobile {
  margin: 20px 0;
  li {
    display: inline-block;
    padding: 5px;
  }
}
.img-payment-plan {
  max-height: 64px;
  display: block;
  margin: 0 auto;
}
.payment-plan-items {
  thead th {
    background-color: #888888;
    color: #fff;
  }
}
.ticket-fixed {
}
.tickets {
  .ticket-folders {
    width: 100%;
    height: 100%;
    border-right: 1px solid #dedede;
    @include tablet {
      padding: .5rem !important;
      width: 180px;
      .folder-desktop {
        .compose {
          margin-top: .5rem;
        }
        .folder-list{
          li {
            margin-bottom: 6px;
            padding: 5px 10px;
            a {
              color: $black;
            }
            &.active {
              border: 1px solid $color-brand;
              border-radius: 20px;
              background: rgb(0 159 145 / 14%);
              a {
                color: $black;

              }
            }
          }

        }
      }
    }
    @include mobile{
      .folder-desktop {
        display: none;
      }
      .folder-mobile {
        .folder-list{
          display: flex;
          justify-content: space-between;
          li {
            &:not(:last-child) {
              border-right: 1px solid #ededed
            }
            &.active {
              background-color: $color-brand;
              a {
                color: $white
              }

            }
            display: flex;
            flex-basis: 0;
            flex-grow: 1;
            flex-shrink: 0;
            justify-content: center;
            padding-top: 5px;
            padding-bottom: 5px;
            border-bottom: 1px solid #ededed;
            a {
              position: relative;
              text-align: center;
              .text {
                display: block;
                font-size: 12px;
              }
              span.tag {
                top: 0;
                right: -12px;
                position: absolute;
              }
            }
          }
        }


      }
    }
    @include tablet{
      .folder-mobile {
        display: none;
      }
    }
  }
  .ticket-list-container {
    border-right: 1px solid #dedede;
    @include tablet-only {
      width: 200px;
    }
    @include desktop-only {
      width: 250px;
    }
    @include widescreen {
      width: 270px;
    }
    width: 100%;
    .search {
      padding: 1.5rem;
      @include mobile {
        padding: 1rem;
      }
    }
    .ticket-list-scroll {
      border-top: 1px solid #dedede;
      @include desktop{
        height: 78vh;
        overflow: auto;
      }

      .ticket-list {
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        flex-direction: row;
        padding: 10px 5px;
        width: 100%;
        color: #303a40;
        .avatar {
          position: relative;
          display: block;
          margin-top: 0;
          margin-right: 3px;
          min-width: 20px;
          img {
            border-radius: 50%;
          }
        }
        .content {
          width: 90%;
          padding-left: 0;
          padding-right: 0;
          .sender-name {
            font-size: 14px;
            font-weight: 400;
            max-width: 100%;
            margin-bottom: 0;
          }

          .message {
            margin-top: 0;
            display: flex;
            .message-title {
              font-size: 14px;
              font-weight: 400;
              max-width: 100%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              margin-bottom: 0;
              -webkit-box-flex: 1;
              -ms-flex: 1 1 auto;
              flex: 1 1 auto;
            }
            .message-date {
              font-size: 11px;
              padding-left: 10px;
              text-align: right;
            }
          }



          .message-text {
            margin: 0;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
          }
        }



        .details {
          width: 5.5%;
          .date {
            text-align: right;
            margin: auto 15px auto 0;
            white-space: nowrap;
          }
          i {
            margin: auto 0;
            color: #ddd;
          }
          &.favorite {
            color: #fcd539;
          }
        }

        &.current_mail {
          background: rgb(0 159 145 / 14%);
          .details .date {
            color: #000000;
          }
        }
        &.new_mail {
          background: #e6e9ed;
          .details .date {
            color: #000000;
          }
          .sender-name {
            font-weight: 600;
          }
        }
      }
    }

  }
  .ticket-content {
    .empty {
      margin-top: 50%;
      text-align: center;
      color: #4eb6ac;
      i {
        font-size: 50px;
      }
    }
    .mail-view {
      .mail-view-header {
        background-color: $color-brand;
      }

      .message-body {
        @include desktop {
          height: calc(78vh + 25px);
          overflow: auto;
        }
        padding: 0 10px 0;
        .sender-details {
          margin-top: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid #e9e9e9;
          display: -webkit-flex;
          display: flex;
          justify-content: space-between;
          .sender-image {
            margin-right: 10px;
            align-self: center;
            border: 1px solid $color-brand;
            border-radius: 999em;
            width: 50px;
            height: 50px;
            img {
              border-radius: 999em;
              width: 100%;
              height: 100%;
              object-fit: cover;
              padding: 2px
            }
          }
          .details {
            flex-grow: 4;
            padding-bottom: 0;
            .msg-subject {
              font-weight: 500;
            }
            .sender-name {
              margin-bottom: 0;
              font-weight: 400;
              font-size: 11px;
            }
          }
          .date-details {
            text-align: right;
            font-size: 13px;
            font-weight: 600;
            .time {
              font-size: 11px;
            }
          }
        }

        .message-content {
          padding: 20px 15px;
        }
        .ticket-comments {
          .ticket-comment {
            .sender-details {
              background-color: #E0E1E2;
              padding: 10px;
              border-radius: 10px;
              .sender-image {
                width: 30px;
                height: 30px;
              }
            }
            .details {
              align-self: center;
              .sender-name {
                font-size: 14px;
                font-weight: 500;
              }
            }
            .comment-text {
              padding: 20px 15px;
              border-bottom: 1px solid #e9e9e9;
            }
          }
        }
        .attachments-sections {
          margin-top: 20px;
          ul {
            list-style: none;
            padding: 10px 15px 0;
            margin-bottom: 0;
            li {
              padding: 10px;
              margin-right: 10px;
              margin-bottom: 10px;
              border: 1px solid #e9e9e9;
              border-radius: 5px;
              display: inline-flex;
              max-width: 200px;
              font-size: 12px;
              background-color: #f9f9f9;
              .thumb {
                display: inline-block;
                margin-right: 10px;
              }

              .thumb i {
                font-size: 30px;
                margin: 0;
                color: #2e383e;
              }
              .details {
                p.file-name-attached {
                  display: block;
                  margin-bottom: 0;
                  color: #2e383e;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  max-width: 156px;
                  white-space: nowrap;

                }
                .buttons {
                  .file-size-attached {
                    margin-right: 10px;
                    margin-bottom: 0;
                    font-size: 11px;
                    display: block;
                  }

                  a {
                    font-size: 13px;
                    margin-right: 10px;
                    &:last-child {
                      margin-right: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .box-comments {
    background: #f7f7f7;
    .box-comment:first-of-type {
      padding-top: 0;
    }
    .box-comment {
      padding: 15px 5px;
      border-bottom: 1px solid #eee;
    }
    .box-comment img {
      float: left;
    }
    .comment-text {
      margin-left: 40px;
      color: #555;
    }
    .username {
      color: #444;
      display: block;
      font-weight: 600;
    }
    .text-muted {
      font-weight: 400;
      font-size: 12px;
    }
  }

  .img-sm, .box-comments .box-comment img, .user-block.user-block-sm img {
    width: 30px !important;
    height: 30px !important;
  }
  .img-sm, .img-md, .img-lg, .box-comments .box-comment img, .user-block.user-block-sm img {
    float: left;
  }

}
.consultant-profile {
  display: flex;
  justify-content: flex-end;
  .profile-content {
    @include desktop {
      text-align: right;
      padding-right: 10px;
    }
    .name {
      color: $color-brand;
      font-weight: 600;
      font-size: 18px;
    }
    .position {
      color: $color-brand;
      font-weight: 500;
    }
    .occupation {
      font-size: 13px;
    }
  }
  .profile-image {
    max-width: 90px;
    align-self: center;
    img {
      border-radius: 50%;
    }
  }
}

//Login

.interactive-bg {
  height: 100vh;
  background-color: $color-brand;
  -webkit-box-shadow: inset 3px 0px 20px 0px #4a4a4ac9;
  -moz-box-shadow: inset 3px 0px 20px 0px #4a4a4ac9;
  box-shadow: inset 3px 0px 20px 0px #4a4a4ac9;
  padding: 0px;
}
@media (max-width: 769px) {
  .interactive-bg{
    display: none
  }
}


.login-logo {
  margin: 0 auto;
  max-height: 130px;
  margin-bottom: 10px;
}

.pending-payment-flag {
  img {
    max-width: 100px;
    @include mobile {
      max-width: 50px;
    }
  }
}

.is-circular {
  border-radius: 50%;
}
//Call
.call-box {
  text-align: center;
}
.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  height: 1.2em;
  white-space: nowrap;
}

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  height: 100vh;
  @include mobile {
    height: calc(100vh - 90px);
  }
  //margin-bottom: 20px;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

  }
}

.languages {
  position: absolute;
  top: 150px;
  left: 0;
  ul {
    li {
      height: 22px;
      img {
        max-width: 25px;
      }
    }
  }
}

.has-video-call {
  .video-call {
    float: right;
    background-color: $red;
    padding: 5px;
    font-size: 12px;
    border-radius: 50px;
    margin-top: -4px;
    color: #FFFFFF
  }
}
.payments {
    margin-bottom: 20px !important;
  h3 {
    font-size: 26px;
    font-weight: 600;
    @include mobile{
      font-size: 20px;
    }
  }
  .payment-box {
    @include  mobile {
      margin-top: -30px;
      p {
        margin-top: -40px !important;
      }
    }
  }
}
.payment-box {
  cursor: pointer;
  @include mobile {
    max-width: 70%;
    margin: 0 auto;
  }

  &:hover {
    img {
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  p {
    font-size: 16px!important;
    margin-top: -30px!important;
    font-weight: 400!important;
    &:hover {
      color: $color-brand;
    }
  }

  .flag-country {
    img {
      max-width: 80%;
      transition: transform .2s; /* Animation */
      &:hover {
       // transform: scale(1.1);
      }
    }
  }
  .cc-icon {
    img {
      margin-top: -14px;
      max-width: 92%;
      transition: transform .2s; /* Animation */
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  &.has-cc-icon {
    p {
      margin-top: -43px!important;
    }
  }
}
.container-payments {
  max-width: 700px;
  margin: 0 auto;
  &.has-payment-plan{
    max-width: 600px;
  }
}
.box-quotation-pay {
  text-align: center;
  h1 {
    font-size: 35px;
    //font-family: 'Montserrat', sans-serif;
  }
  .gif-quotation {
    width: 100%;
    max-width: 43%;
    @include mobile {
      max-width: 60%;
    }
  }
  p {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
  }
  .pay-later {
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;
  }
}
.student-menu-icon {
  height: 15px;
  vertical-align: middle;
  margin-bottom: 2px;

  .is-active & {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7437%) hue-rotate(117deg) brightness(130%) contrast(113%);
  }
}
.visa-status {
  .title {
    margin-bottom: 0;
    color: black;
    font-size: 28px;
  }

  .timeline-container {
    position: relative;
    margin: auto;

    ul.timeline-list {
      position: relative;
      margin: 40px 0 0;
      padding: 0;

      li {
        list-style: none;
        margin: 0;
        min-height: 80px;
        position: relative;
        padding-left: 40px;
        display: flex;
        align-items: flex-start;

        &.is-completed {
          margin-bottom: 20px;

          &:first-child::after {
            content: "";
            position: absolute;
            left: 17px;
            top: 45%;
            height: calc(100% - 30px);
            width: 1px;
            background: #04bca6;
          }

          &:not(:last-child):not(:first-child)::after {
            content: "";
            position: absolute;
            left: 17px;
            top: calc(50% + 5px);
            height: calc(100% - 35px);
            width: 1px;
            background: #04bca6;
          }
        }

        &:not(:last-child)::after {
          content: "";
          position: absolute;
          left: 17px;
          top: calc(50% + 5px);
          height: calc(100% - 55px);
          width: 1px;
          background: #7070F2;
        }

        .item-icon {
          position: absolute;
          left: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background: #7070F2;

          &:hover {
            transform: scale(1.1);
            transition: transform 0.2s;
          }

          &.is-completed {
            background: #04bca6;
            color: white;
          }

          &.refused {
            background: white;
            border: 1px solid #F59E0B;
          }

          &.approved {
            background: #2395b7;
            color: white;
          }

          img {
            max-width: 24px;
            max-height: 24px;
          }
        }

        .item-text {
          flex-grow: 1;
          margin-left: 20px;

          .item-title {
            display: flex;
            justify-content: space-between;
            color: #7070F2;
            font-weight: bold;
            font-size: 16px;

            &.is-completed {
              color: #04bca6;
            }

            &.refused {
              color: #F59E0B;
            }

            &.approved {
              color: #2395b7;
            }

            .item-timestamp {
              color: #909090;
              font-size: 14px;
              font-weight: normal;
              text-align: right;
              margin-left: 20px;
            }
          }

          .item-guide {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
              width: 48px;
              height: 48px;
              object-fit: cover;
              border-radius: 50%;
              border: 1px solid #e6e6e6;
            }

            span {
              font-weight: bold;
            }
          }

          .item-description {
            color: #323232;
            font-size: 14px;

            &.is-completed {
              color: #04bca6;
            }

            &.approved {
              display: flex;
              justify-content: space-between;
              align-items: center;
              max-width: 600px;
              padding: 10px 15px;
              background: #e7f2fe;
              color: #2395b7;
              border: 1px solid #2395b7;
              border-radius: 4px;

              .congrats {
                font-weight: bold;
              }

              .image-container img {
                max-height: 48px;
              }
            }
          }

          .item-last-checked {
            color: #0f77f0;
            font-style: italic;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .timeline-container ul.timeline-list li {
      flex-direction: column;

      &.is-completed {
        &:first-child::after {
          top: 35%;
          height: calc(100% - 40px);
        }
      }

      .item-text {
        .item-title {
          display: block;

          .item-timestamp {
            margin-left: 0;
            text-align: left;
          }
        }
      }
    }
  }
}

.image-guide {
  margin: 0 auto;
  width: 170px;
  height: 170px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &.rounded {
      border-radius: 50%;
    }
  }
}