.tribute-container {
    margin-top: 1rem;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    background: white;

    ul {
        padding: .5rem 0;
        margin: 0;

        li {
            padding: 1rem 2rem;
            margin: 0;
            color: black;
            list-style-type: none;

            &.highlight, &:hover {
                background: #ddd;
            }
        }
    }
}
